import { DeviceStatus } from '@hiven-energy/hiven-client';
import { useEffect, useMemo } from 'react';

import { useScheduledCall } from 'src/hooks/useScheduledCall';
import { useDeviceStatus } from 'src/queries/sdk';
import { getDate } from 'src/utils/date';
import { getNextUpdateDate } from 'src/utils/next-update';

import { statusToChargingState } from './constants';

export const useOnNextStatusUpdate = (
  deviceId: string,
  {
    interval,
    callback,
    timestamp,
    enabled,
  }: {
    timestamp: string | undefined;
    interval: number;
    callback: VoidFunction;
    enabled: boolean;
  },
) => {
  const { data: deviceStatus = DeviceStatus.NOT_REGISTERED } = useDeviceStatus(deviceId);
  const nextStatusUpdate = useMemo(() => {
    const lastUpdate = getDate(timestamp);
    return getNextUpdateDate(lastUpdate, interval);
  }, [timestamp, interval]);

  useScheduledCall(callback, {
    targetDate: nextStatusUpdate,
    enabled,
  });

  useEffect(() => {
    if (
      enabled &&
      (statusToChargingState[deviceStatus] ||
        deviceStatus === DeviceStatus.CABLE_PLUGGED ||
        deviceStatus === DeviceStatus.CHARGING_COMPLETED ||
        deviceStatus === DeviceStatus.CHARGING_CANCELLED ||
        deviceStatus === DeviceStatus.SMART_CHARGING_COMPLETED)
    ) {
      callback();
    }
  }, [deviceStatus, callback, enabled]);

  return nextStatusUpdate;
};
